<template>
  <div>
    <!-- Back Button -->
    <v-btn icon @click="openModal" class="ml-minus-2" :disabled="investorClassificationLoading">
      <v-icon>
        mdi-arrow-left
      </v-icon>
    </v-btn>

    <!-- Title -->
    <h1 class="my-5 font-weight-medium">{{ $t('settings.investorClassification.title') }}</h1>

    <!-- Step -->
    <component
      :is="investorClassificationStep"
      settings
      ref="step"
    />

    <!-- Navigation Buttons -->
    <div :class="[lastStep ? 'btn-container-big' : 'btn-container', 'mt-5']">
      <CardNav
        :nextText="nextText"
        :prevText="$t('settings.investorClassification.btn.back')"
        @next="next"
        @previous="back"
        :nextDisabled="!isFormValid"
        :nextLoading="investorClassificationLoading"
        :prevDisabled="investorClassificationLoading"
        :vertical="$vuetify.breakpoint.smAndDown"
      />
    </div>

    <!-- Cancel Modal -->
    <Modal
      :dialog="isModalOpen"
      @close="closeModal"
      noPadding
    >
      <template v-slot:content>
        <div class="mt-5 d-flex flex-column defaultText--text">
          <p class="font-weight-medium">
            {{ $t('settings.investorClassification.modal.title') }}
          </p>
          <p>
            {{ $t('settings.investorClassification.modal.desc') }}
          </p>
          <CardNav
            class="mt-3"
            :nextText="$t('settings.investorClassification.modal.yes')"
            :prevText="$t('settings.investorClassification.modal.no')"
            @next="cancel"
            @previous="closeModal"
          />
        </div>
      </template>
    </Modal>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';
import CardNav from '@/components/Shared/CardNav.vue';
import Modal from '@/components/Shared/Modal.vue';

export default {
  name: 'SettingsInvestorClassification',
  components: {
    Acknowledgement: lazyLoad('views/Onboarding/Classification/Acknowledgement'),
    Conditions: lazyLoad('views/Onboarding/Classification/Conditions'),
    InvestorClassification: lazyLoad('views/Onboarding/Classification/InvestorClassification'),
    Overview: lazyLoad('views/Onboarding/Classification/Documents'),
    CardNav,
    Modal,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters('settings', [
      'investorClassificationIndex',
      'investorClassificationStep',
      'investorClassificationLoading',
    ]),
    ...mapGetters('onboarding', [
      'isFormValid',
    ]),
    lastStep() {
      return this.investorClassificationStep === 'Overview';
    },
    nextText() {
      if (this.lastStep) {
        return this.$t('settings.investorClassification.btn.save');
      }
      return this.$t('settings.investorClassification.btn.next');
    },
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    ...mapActions('settings', [
      'reset',
      'previousInvestorClassificationStep',
    ]),
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    cancel() {
      this.$router.push('/settings/knowledge-experience');
    },
    back() {
      if (this.investorClassificationIndex === 0) {
        this.openModal();
      } else {
        this.previousInvestorClassificationStep();
      }
    },
    next() {
      this.$refs.step.edit();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  width: 465px;
}
.btn-container-big {
  width: 730px;
}
@media (max-width: 600px) {
  .btn-container {
    width: 100%;
  }
  .btn-container-big {
    width: 100%;
  }
}
</style>
